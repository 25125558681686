const form = document.getElementById('pipeline-followup-form');
const checkboxes = form?.querySelectorAll('input[type="checkbox"]');

const disableInputs = () => {
  checkboxes?.forEach(c => (c.disabled = true));
};

const onChange = _ => {
  form.submit();
  disableInputs();
};

checkboxes?.forEach(box => {
  box.onchange = onChange;
});
